import React, { useState } from "react"
import { useMutation, useQuery } from "@apollo/client"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Modal from "@material-ui/core/Modal"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import { makeStyles } from "@material-ui/core/styles"
import { useForm } from "react-hook-form"
import LoadingView from "../../components/LoadingView"
import Layout from "../components/Layout"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { ADMIN_DELETE_USER_MUTATION, ADMIN_SQL_QUERY } from "../queries"
import { AdminDeleteUserMutation, AdminDeleteUserMutationVariables } from "../../../__generated__/AdminDeleteUserMutation"

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "30%",
    left: "30%",
  },
  confirmationButtons: {
    "& > *": {
      margin: theme.spacing(1),
    },
    fontSize: "16px",
    marginTop: "25px",
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const FindUserForm = ({ isSearching, setCustId }) => {
  const { register, handleSubmit } = useForm()

  const onSubmit = async (data) => {
    const { custId } = data
    setCustId(custId)
  }

  return (
    <>
      <Typography title="Find User">Find User</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          id="custId"
          name="custId"
          label="Cust Id"
          style={{ margin: 8 }}
          placeholder="id123"
          helperText="user custId"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register}
        />
        <br />
        <Button variant="contained" color="primary" disabled={isSearching} type="submit">
          {isSearching ? `Searching...` : `Search`}
        </Button>
      </form>
    </>
  )
}

const DeleteUserConfirmationModal = ({ user, open, handleClose, isDeleting, handleDeleteUser }) => {
  const classes = useStyles()

  const handleDeleteUserClicked = async () => {
    await handleDeleteUser({
      variables: {
        custId: user.custId,
      },
    })
  }

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <div className={classes.paper}>
        Are you sure you want to delete {user.firstName || ""} {user.lastName || ""}?
        <br />
        This will delete all Entries, Picks, PeriodPoints, Messages, Finances, and Groups for this user.
        <div className={classes.confirmationButtons}>
          <Button variant="contained" disabled={isDeleting} onClick={handleDeleteUserClicked}>
            Yes
          </Button>
          <Button variant="contained" color="primary" disabled={isDeleting} onClick={handleClose}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const UserDetails = ({ user, isDeleting, handleDeleteUser }) => {
  const [open, setOpen] = React.useState(false)

  if (!user) {
    return null
  }

  const handleClose = () => {
    setOpen(false)
  }

  const attributes = ["id", "firstName", "lastName", "email", "custId", "userLogin"]
  return (
    <>
      <Typography title="Verify User Details">Verify User Details</Typography>
      <div>
        <List dense>
          {attributes.map((attr) => {
            return (
              <ListItem key={attr}>
                <ListItemText primary={user[attr]} secondary={attr === "id" ? `Picks DB ID` : attr} />
              </ListItem>
            )
          })}
        </List>
        <Button
          variant="contained"
          color="primary"
          disabled={isDeleting}
          onClick={() => {
            setOpen(true)
          }}
        >
          {isDeleting ? `Deleting...` : `Delete`}
        </Button>
      </div>
      <DeleteUserConfirmationModal user={user} open={open} handleClose={handleClose} isDeleting={isDeleting} handleDeleteUser={handleDeleteUser} />
    </>
  )
}

const DeleteUser = () => {
  const [custId, setCustId] = useState<string | undefined>(undefined)

  const findUserQuery = useQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, {
    variables: {
      statement: `SELECT * FROM User where custId = ${custId}`,
    },
    skip: !custId,
  })

  const user = findUserQuery.data?.sql?.[0]

  const [mutate, mutationResult] = useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(ADMIN_DELETE_USER_MUTATION)

  const loading = findUserQuery.loading || mutationResult.loading

  return (
    <Layout title="User - Delete User">
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={!!mutationResult.data}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#05A342",
          }}
          message={mutationResult.data?.adminDeleteUser || ""}
        />
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={!!mutationResult.error}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#CC0E00",
          }}
          message={mutationResult.error?.message || "An error has occurred. Please contact a picks developer and do not refresh the screen."}
        />
      </Snackbar>
      <Typography variant="body2" component="div" color="textSecondary">
        <li>PLEASE NOTE: THIS ACTION IS PERMANENT</li>
        <li>This Action is only enabled for CBS Admins only, and all Admins must be signed in prior.</li>
        <li>First search for a cbs user by their login id</li>
        <li>VERIFY THE USER IS THE CORRECT ONE TO BE DELETE</li>
        <li>If this is the correct user to be deleted, click delete.</li>
        <li>This will delete all Entries, Picks, PeriodPoints, Messages, Finances, and Groups for this user.</li>
        <li>Copy + Paste the successful message and pass that along to a picks developer in order to keep track of the items that were deleted.</li>
        <li>
          If an error occurs, DO NOT refresh. Take a screen shot of the screen, or copy + paste the error that is displayed and contact a picks
          developer.
        </li>
      </Typography>
      <br />
      <FindUserForm isSearching={findUserQuery.loading} setCustId={setCustId} />
      <br />
      {!loading && user && <UserDetails user={user} isDeleting={false} handleDeleteUser={mutate} />}
      {loading && <LoadingView />}
    </Layout>
  )
}

export default DeleteUser
