import React, { FC } from "react"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"

export type MessageVariantType = "DEFAULT" | "ERROR"
interface IMessageProps {
  variant?: MessageVariantType
}
const BgColorVariant: Record<MessageVariantType, string> = {
  ERROR: "error.main",
  DEFAULT: "background.default",
}

const Message: FC<IMessageProps> = ({ children, variant = "INFO" }) => {
  const bgcolor = BgColorVariant[variant]
  const color = variant === "ERROR" ? "white" : "palette.text.primary"

  return (
    <Paper>
      <Box p={5} textAlign="center" bgcolor={bgcolor} color={color}>
        {children}
      </Box>
    </Paper>
  )
}

export default Message
