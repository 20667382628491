import React, { FC } from "react"
import { useMutation } from "@apollo/client"
import Layout from "../components/Layout"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { ScoreFFBOPC, ScoreFFBOPCVariables } from "../../../__generated__/ScoreFFBOPC"
import { SCORE_FFBOPC } from "../queries"
import Message from "../Analytics/Message"

const FFBOpc: FC = () => {
  const [scoreFullSeason, setScoreFullSeason] = React.useState<boolean>(false)
  const [scoreFFBOPCMutation, { data, error, loading }] = useMutation<ScoreFFBOPC, ScoreFFBOPCVariables>(SCORE_FFBOPC)

  const handleClick = () => {
    scoreFFBOPCMutation({
      variables: {
        scoreFullSeason,
      },
    })
  }

  return (
    <Layout title={`Scoring - FFBOPC`}>
      <Box mb={3}>
        <FormControlLabel
          control={<Checkbox name="checkedA" checked={scoreFullSeason} onChange={(_, checked) => setScoreFullSeason(checked)} />}
          label="Score full season"
        />
        <Button color="primary" variant="contained" onClick={handleClick} disabled={loading}>
          Score FFBOPC
        </Button>
      </Box>
      {data?.scoreFFBOpc && <Message>{data?.scoreFFBOpc}</Message>}
      {error && <Message variant="ERROR">{error.message}</Message>}
    </Layout>
  )
}

export default FFBOpc
