import React, { FC } from "react"
import { useMutation } from "@apollo/client"
import Layout from "../components/Layout"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { ScoreOPC, ScoreOPCVariables } from "../../../__generated__/ScoreOPC"
import { SCORE_OPC } from "../queries"
import Message from "../Analytics/Message"

const Opc: FC = () => {
  const [scoreFullSeason, setScoreFullSeason] = React.useState<boolean>(false)
  const [scoreOPCMutation, { data, error, loading }] = useMutation<ScoreOPC, ScoreOPCVariables>(SCORE_OPC)

  const handleClick = () => {
    scoreOPCMutation({
      variables: {
        scoreFullSeason,
      },
    })
  }

  return (
    <Layout title={`Scoring - OPC`}>
      <Box mb={3}>
        <FormControlLabel
          control={<Checkbox name="checkedA" checked={scoreFullSeason} onChange={(_, checked) => setScoreFullSeason(checked)} />}
          label="Score full season"
        />
        <Button color="primary" variant="contained" onClick={handleClick} disabled={loading}>
          Score OPC
        </Button>
      </Box>
      {data?.scoreOpc && <Message>{data?.scoreOpc}</Message>}
      {error && <Message variant="ERROR">{error.message}</Message>}
    </Layout>
  )
}

export default Opc
